@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  scroll-behavior: smooth;
}

:root {
  --thumbBG: #f8f8f850;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: #f8f8f850 transparent;
  scrollbar-color: var(--thumbBG) transparent;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  margin-left: 5px;
  width: 5px;
}
::-webkit-scrollbar-track {
  margin-left: 5px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #f8f8f850;
  background-color: var(--thumbBG);
  border-radius: 6px;
}

* {
  margin: 0;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.loading::before {
  animation: dots 1.5s linear infinite;
  content: "";
}

@keyframes dots {
  0% {
    content: "";
  }
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80% {
    content: "...";
  }
  90% {
    content: "";
  }
  100% {
    content: "";
  }
}

.MuiFilledInput-input:-webkit-autofill {
  -webkit-box-shadow: none !important;
  -webkit-text-fill-color: #11093e !important;
}

.hideNav-before {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
}

.hideNav-after {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
}

.activeTab {
  color: rgba(255, 255, 255, 0.616) !important;
}

.link {
  text-decoration: none;
}

.hidePopUp-before {
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
}

.hidePopUp-after {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

